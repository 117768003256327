import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import { AntDesign } from '@expo/vector-icons';
import StoreMenuOpen from "../store/StoreMenuOpen";
import StoneCircle from "./StoneCircle";

const CoverColorInfoContainer = ({ 
  userSettingsState, 
  foundItem,
  stoneColor,
  isStoneMenuOpen,
  setIsStoneMenuOpen,
 }) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);

  const [isHover, setIsHover] = useState(false);

  
  const isHasMaterial = foundItem.IsMotherOfPearl || foundItem.IsMalachite || foundItem.IsBlackAgate
  || foundItem.IsRedAgate || foundItem.IsLapisLazuli || foundItem.IsTigersEye;


  if(!isHasMaterial)
  {
    return null;
  }


  return (
    <>
    <TouchableOpacity 
    style={styles.container}
    onPress={() => {
      setIsStoneMenuOpen(true);
    }}
    onMouseEnter={() => setIsHover(true)}
    onMouseLeave={() => setIsHover(false)}
    >
      <View style={{ 
        flex: 1, 
        flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row', 
        justifyContent: 'space-around',
        alignItems: 'center',
        }}>

        <View style={{ flex: 1, flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row' }}>
        <View style={{ 
          marginRight: !responsiveStyles.isRTL ? 20 : null,
          marginLeft: responsiveStyles.isRTL ? 20 : null,
         }}>
        <Text style={[styles.text, isHover ? { color: GlobalStyles.colors.Lavender } : null]}>{i18n.t('store.CoverColorInfoContainer_title')}</Text>
      </View>
      <StoneCircle
          userSettingsState={userSettingsState}
          stoneColor={stoneColor}
          ValueOfStoneColorType={stoneColor}
          />
      </View>

      <AntDesign name={responsiveStyles.isRTL ? "left" : "right"}
                size={12} 
                color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} 
                style={styles.ArrowIcon}
                 />
      </View>


      
    </TouchableOpacity>
    </>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    // flex: 1,
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    marginBottom: 20,
    
    paddingTop: 20,
    borderTopWidth: 1,
    paddingBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: GlobalStyles.colors.TitleBorderBottomColor, 
    borderTopColor: GlobalStyles.colors.TitleBorderBottomColor, 
  },
  colorWrapper: {
    padding: 2, // Add small padding between border and color
    borderRadius: 50, // Ensure borderRadius matches the border
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.iconColor,
    borderWidth: 1,
    marginTop: -2,
  },
  colorMainContainer: {
    marginLeft: !responsiveStyles.isRTL ? 20 : null,
    marginRight: responsiveStyles.isRTL ? 20 : null,
  },
  colorContainer: {
    width: 25,
    height: 25,
    borderRadius: 50, // Matches the wrapper's borderRadius
    backgroundColor: 'transparent', // Will be overridden dynamically
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  tooltip: {
    position: 'absolute',
    top: -40, // Position above the color container
    left: '50%',
    transform: [{ translateX: '-50%' }], // Corrected syntax
    padding: 5,
    backgroundColor: 'black',
    borderRadius: 5,
    zIndex: 1, // Ensure tooltip is above other content
  },
  tooltipText: {
    color: 'white',
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    minWidth: 150,
    textAlign:'center'
  },
});

export default CoverColorInfoContainer;
