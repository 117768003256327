import React from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import { GlobalStyles } from '../../constants/styles';

const LapisLazuliComponent = ({ userSettingsState }) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);

  return (
    <View>
      <Text style={styles.title}>Lapis Lazuli</Text>
      <Text style={styles.text}>
        Lapis Lazuli is a deep blue semi-precious stone admired for its vibrant color and golden flecks, which come from traces of pyrite. Its name derives from Latin and Arabic words meaning "blue stone." Highly valued since ancient times, Lapis Lazuli has been used in jewelry, carvings, and even as a pigment for artwork.
      </Text>

      {/* Placeholder for images */}
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginVertical: 10 }}>
        <View style={{ flex: 1, marginRight: 5 }}>
          <Image 
            source={{ uri: 'https://upload.wikimedia.org/wikipedia/commons/3/33/Lapis_lazuli_polished.jpg' }} 
            style={{ width: '100%', height: 100, resizeMode: 'cover' }} 
          />
          <Text style={styles.text}>
            Polished Lapis Lazuli gemstone
          </Text>
        </View>
        <View style={{ flex: 1, marginLeft: 5 }}>
          <Image 
            source={{ uri: 'https://upload.wikimedia.org/wikipedia/commons/6/65/Lapis_lazuli_raw.jpg' }} 
            style={{ width: '100%', height: 100, resizeMode: 'cover' }} 
          />
          <Text style={styles.text}>
            Raw Lapis Lazuli specimen
          </Text>
        </View>
      </View>

      <Text style={styles.text}>
        Lapis Lazuli has a rich history, dating back to ancient Egypt, Mesopotamia, and Persia, where it was often associated with royalty and divinity. Ancient Egyptians used it to create scarabs and amulets, while Renaissance artists ground it into powder to produce ultramarine, one of the most valuable blue pigments. The stone is also believed to enhance wisdom, truth, and spiritual connection.
      </Text>

      <Text style={styles.title}>Care Recommendations</Text>
      <Text style={styles.text}>
        Lapis Lazuli is a relatively soft stone, so proper care is essential to preserve its vibrant color and surface. Follow these recommendations:
      </Text>
      <Text style={styles.text}>
        - Store Lapis Lazuli jewelry in a soft-lined case, away from harder stones that could scratch its surface.
      </Text>
      <Text style={styles.text}>
        - Avoid prolonged exposure to sunlight, as it may fade the intense blue color of the stone.
      </Text>
      <Text style={styles.text}>
        - Protect the stone from chemicals such as perfumes, lotions, and cleaning products, which can erode its surface.
      </Text>
      <Text style={styles.text}>
        - Clean the stone with a soft, damp cloth. Avoid soaking it in water or using abrasive cleaners.
      </Text>
      <Text style={styles.text}>
        - Avoid wearing Lapis Lazuli jewelry during high-impact activities to prevent chips or cracks.
      </Text>
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  title: {
    fontWeight: 'bold',
    fontSize: responsiveStyles.fontSize_H4,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    paddingBottom: 20,
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H6,
    fontFamily: GlobalStyles.fonts.application_font,
    lineHeight: 20,
  },
});

export default LapisLazuliComponent;
