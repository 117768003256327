export default {
    Necklaces: "שרשראות",
    Bracelets: "צמידים",
    CoupleJewelry: "תכשיטים לזוגות",
    PetsTag: "בעלי חיים",
    HomeScreen_HeaderImageContainer_ScanText: "לחצו עלי / סרקו כדי לנסות",
    HomeScreen_HeaderImageContainer_Title: "תכשיטי טכנולוגיית NFC הראשון בעולם!",
    HomeScreen_HeaderImageContainer_Description: "שתף את הזיכרונות, התמונות, הסרטונים ועוד איתם.",
    HomeScreen_HeaderImageContainer_NFC_Title: "תכשיט ה-NFC הראשון בעולם",
    HomeScreen_HeaderImageContainer_NFC_Description: "עולם של זכרונות, לנצח איתך",
    HomeScreen_HeaderImageContainer_NFC_Button: `גלו את הקולקציה שלנו`,
    HomeScreen_OurStoryContainer_SectionTitle: `הסיפור שלנו`,
    HomeScreen_OurStoryContainer_Section1_1: `ב - `,
    HomeScreen_OurStoryContainer_Section1_2: `Timeless Memories Jewelry (TMJ)`,
    HomeScreen_OurStoryContainer_Section1_3: `, אנחנו מאמינים שהרגעים היקרים ביותר בחיים צריכים להישמר לנצח. אנחנו לא רק מייצרים תכשיטים, אלא יוצרים שער לזכרונות היקרים ביותר שלך. כל תכשיט מכסף סטרלינג 925 שלנו מגיע עם ברקוד חרוט ייחודי, שמאפשר לך לגשת לתמונות, סרטונים, והודעות מלאות ברגש שנשמרו במיוחד עבורך על ידי האנשים החשובים לך ביותר.`,
    HomeScreen_OurStoryContainer_Section2: `דמיינו לתת לאהוביכם מתנה שהיא לא רק שרשרת יפיפייה, אלא גם אוצר של זכרונות שהם יכולים לחזור אליהם בכל רגע. בסריקה פשוטה, הם נשאבים מחדש לרגעים שהפכו את הקשר שלכם למיוחד כל כך. בין אם זה בעל שמפתיע את אשתו, ילד שמביע אהבה לאמו, או חברה שמציינת את הדרך שעברתן יחד, TMJ הוא יותר מסתם תכשיט – הוא הדרך לשמור את הקשרים האלו חיים.`,
    HomeScreen_OurStoryContainer_Section3: `מה מייחד אותנו? המחויבות שלנו לשלב אלגנטיות נצחית עם טכנולוגיה מודרנית. אנו מבינים שמתנה צריכה להיות יותר מסתם חפץ; היא צריכה לשאת בתוכה חלק מהלב שלך. התכשיטים שלנו לא רק מקשטים, אלא גם מספרים סיפור – סיפור שהוא אישי, רגשי, ובלתי נשכח.`,
    HomeScreen_OurStoryContainer_Section4: `עם TMJ, אתם לא רק עונדים תכשיט; אתם עונדים זכרונות. אנו מזמינים אתכם לחקור את הקולקציה שלנו ולגלות איך תוכלו להפוך את הרגעים שלכם לנצחיים.`,
    HomeScreen_OurStoryContainer_Slogan: `"TMJ - כשהעולם הפיזי פוגש את הדיגיטלי – זיכרונות שנשארים איתך לנצח."`,
    HomeScreen_StepContainer_SectionTitle: "רק 3 צעדים פשוטים",
    HomeScreen_StepContainer_Section1_Title: "שלב",
    HomeScreen_StepContainer_Section1_Description: "קנה מתנה",
    HomeScreen_StepContainer_Section2_Title: "שלב",
    HomeScreen_StepContainer_Section2_Description: "צור חשבון",
    HomeScreen_StepContainer_Section3_Title: "שלב",
    HomeScreen_StepContainer_Section3_Description: "הגדר פרופיל ליקיריך",
    HomeScreen_StepMemoriesContainer_Title: "שתף את הזיכרונות שלך",
    HomeScreen_StepMemoriesContainer_Section1_Title: "הורה",
    HomeScreen_StepMemoriesContainer_Section2_Title: "בן אדם",
    // HomeScreen_StepMemoriesContainer_Section2_Title: "בן/בת זוג",
    HomeScreen_StepMemoriesContainer_Section3_Title: "חבר/ה הכי טוב/ה",
    HomeScreen_StepMemoriesContainer_Section4_Title: "חיית מחמד",
    HomeScreen_linkExistingUserText: "אם אתה רוצה לרכוש תכשיט נוסף לאותו אדם, תוכל לקשר אותו לאדם קיים",
    HomeScreen_StoreContainers_Title: `מוצרים`,
    HomeScreen_StoreContainers_SubTitle: "הצטרפו אלינו היום והתחל לחגוג את הקשרים שלך",
    HomeScreen_Review1_Name: "דוד ע.",
    HomeScreen_Review1_Subtitle: "מתנת חג המולד שתשאר לנצח",
    HomeScreen_Review1_Description: "הענקתי את זה לאחיי בתור מתנת חג המולד כדי לזכור את ההורים שלנו. זה מתנה אמיתית להיות מסוגל לשמור עליהם בכל פעם שאנו מבקרים באתר הקבורה שלהם. המוצר הזה מושלם ואנחנו מודים עליו. תודה.",
    HomeScreen_Review2_Name: "ניקול ד.",
    HomeScreen_Review2_Subtitle: "מתנה נפלאה לחברה שלי",
    HomeScreen_Review2_Description: "הענקתי את זה לחברה שלי והיא אהבה את זה!!",
    HomeScreen_Review3_Name: "מאיה ס.",
    HomeScreen_Review3_Subtitle: "דבר מיוחד לאמא שלי",
    HomeScreen_Review3_Description: "ליום ההולדת ה-50 של אמא שלי, אני ואחי החלטנו לקנות את זה לאמא שלנו. העלינו את כל הזיכרונות שלנו, תמונות, סרטונים ואפילו הקלטות ישנות והיא התאהבה.",
    HomeScreen_GuaranteesGrid_ThumbsUp: "החזרות ללא טרחה",
    HomeScreen_GuaranteesGrid_Calendar: "אחריות לכל החיים חינם",
    HomeScreen_GuaranteesGrid_DoneOutline: "הסיפוק שלך מובטח 100%",
    HomeScreen_GuaranteesGrid_StarBorder: "משלוח ברחבי העולם",
    "": "",
    StoreBreadcrumbs_Home: "בית",
    CartEmptyListComponent_Title: "עגלת הקניות שלך ריקה כרגע.",
    CartBreadcrumbs_Cart: "עגלת קניות",
    CartBreadcrumbs_Information: "מידע",
    CartBreadcrumbs_Shipping: "משלוח",
    CartBreadcrumbs_Payment: "תשלום",
    OrderSummeryComponent_Show: "הצג",
    OrderSummeryComponent_Hide: "הסתר",
    OrderSummeryComponent_SummeryText: "סיכום הזמנה",
    AddToCart: "הוסף לעגלה",
    ChooseOptions: `בחר אפשרויות`,
    ItemDetailsScreen_Description_text1: `כסף סטרלינג 925 עם טכנולוגית NFC`,
    ItemDetailsScreen_Description_text2: `מידות התכשיט (בקירוב): אורך: 45 ס"מ`,
    ItemDetailsScreen_DescriptionContainer_text1: "שתף תמונות, סרטונים, זיכרונות, מחוות ועוד ללא הגבלה",
    ItemDetailsScreen_DescriptionContainer_text2: "שתף עם משפחה וחברים כדי לאסוף עוד זיכרונות",
    ItemDetailsScreen_DescriptionContainer_text3: "תוכל להחליט איזה פרופיל משתמש אתה רוצה להקצות",
    JewelryLengthContainer_Chain_text1: `אורך שרשרת בס"מ`,
    JewelryLengthContainer_Bracelet_text1: `אורך שרשרת בס"מ`,
    JewelryLengthContainer_Ring_text1: `אורך שרשרת בס"מ`,


    ItemDetailsScreen_DetailsContainer_sizeGuide_header: `מדריך מידות`,
    ItemDetailsScreen_DetailsContainer_details_header: `פרוט`,
    ItemDetailsScreen_DetailsContainer_description_header: `תאור`,
    ItemDetailsScreen_DetailsContainer_materials: `חומרים`,
    ItemDetailsScreen_DetailsContainer_materials_text1: `כסף סטרלינג 925.`,
    ItemDetailsScreen_DetailsContainer_dimensions: `מידות`,
    ItemDetailsScreen_DetailsContainer_dimensions_chain: `גודל תליון`,
    ItemDetailsScreen_DetailsContainer_dimensions_chain_text1: `ס"מ.`,
    ItemDetailsScreen_DetailsContainer_dimensions_pendant: "אורך השרשרת",
    ItemDetailsScreen_DetailsContainer_dimensions_pendant_text1: `ס"מ.`,
    ItemDetailsScreen_DetailsContainer_weight: "משקל",
    ItemDetailsScreen_DetailsContainer_weight_text1: `מ"ג.`,

    BraceletSizeGuide_mm: `מ"מ`,
    BraceletSizeGuide_in: `אינץ'`,
    BraceletSizeGuide_cm: `ס"מ`,
    BraceletSizeGuide_sizeGuide_rings: `טבעות`,
    BraceletSizeGuide_sizeGuide_bracelets: `צמידים`,
    BraceletSizeGuide_sizeGuide_necklacesAndPendants: `שרשראות ותליונים`,
    BraceletSizeGuide_sizeGuide_usSize: `מידה אמריקאית`,
    BraceletSizeGuide_sizeGuide_insideCircumference: `היקף פנימי`,
    BraceletSizeGuide_sizeGuide_braceletsHeader: `צמידים`,
    BraceletSizeGuide_sizeGuide_wristMeasurements: `מדידות מפרק כף היד`,
    BraceletSizeGuide_sizeGuide_lengthIn: `אורך (אינץ')`,
    BraceletSizeGuide_sizeGuide_lengthCm: `אורך (ס"מ)`,

    faqs_header: `שאלות ותשובות`,
    faq_nfc_devices: "לאילו מכשירים מתאים השימוש ב-NFC?",
    faq_nfc_devices_answer: "השימוש בשבב ה-NFC אפשרי ברוב הטלפונים החכמים המודרניים התומכים בטכנולוגיה זו. מכשירי אנדרואיד כוללים תמיכה ב-NFC בגירסאות מוקדמות יחסית, בעוד שמכשירי iPhone תומכים בטכנולוגיה החל מ-iPhone 7 עם מערכת הפעלה iOS 11 ומעלה.",
    faq_nfc_usage: "מה ניתן לעשות עם התכשיט שמכיל NFC?",
    faq_nfc_usage_answer: "התכשיט מציע גישה לטכנולוגיה חדשנית שמאפשרת סריקה מהירה של השבב כדי לצפות בזיכרונות היקרים ביותר של המשתמש.",
    faq_nfc_scan: "האם כל אחד יכול לסרוק את התכשיט?",
    faq_nfc_scan_answer: "כן, כל אחד עם טלפון חכם התומך ב-NFC יכול לסרוק את התכשיט.",
    faq_custom_design: "האם ניתן להזמין עיצובים מיוחדים בהתאמה אישית?",
    faq_custom_design_answer: "נכון לעכשיו, איננו מציעים עיצוב אישי כסטנדרט, אך ניתן ליצור קשר לקבלת פרטים על אפשרויות עיצוב בתשלום נוסף.",
    faq_shipping_time: "כמה זמן לוקח המשלוח?",
    faq_shipping_time_answer: "נכון לעכשיו, זמן המשלוח עשוי לקחת עד 30 ימים מרגע ביצוע ההזמנה.",
    faq_warranty: "האם יש אחריות על התכשיטים?",
    faq_warranty_answer: "כן, ישנה אחריות של חצי שנה על התכשיטים החל מרגע קבלת המוצר על ידי הלקוח.",

    ItemDetailsScreen_EngravingTextContainer_frontSideText_label: `טקסט לפנים`,
    ItemDetailsScreen_EngravingTextContainer_backSideText_label: `טקסט מאחור`,
    ItemDetailsScreen_EngravingTextContainer_chainSize_label: `גודל שרשרת`,
    ItemDetailsScreen_EngravingTextContainer_braceletSize_label: `גודל צמיד`,
    ItemDetailsScreen_EngravingTextContainer_ringSize_label: `גודל טבעת`,
    ItemDetailsScreen_EngravingTextContainer_note: `כמות תווים מקסימלית 20`,


    ItemDetailsScreen_DevileryDetailsContainer_Location: "תל אביב",
    ItemDetailsScreen_DevileryDetailsContainer_FreeDeliveryShipping: "משלוח חינם",
    MaterialColorPickerContainer_title: `צבע החומר`,
    MaterialColorPickerContainer_Silver: `כסף`,
    MaterialColorPickerContainer_Gold: 'זהב',
    PendentColorPickerContainer_title: `צבע אבן`,
    PendentColorPickerContainer_MotherOfPearl: `אם הפנינה`,
    PendentColorPickerContainer_Malachite: `מלכיט`,
    PendentColorPickerContainer_BlackAgate: `אגת שחור`,
    PendentColorPickerContainer_RedAgate: `אגת אדום`,
    PendentColorPickerContainer_LapisLazuli: `לאפיס לזולי`,
    PendentColorPickerContainer_TigersEye: `עין הנמר`,
    CoverColorInfoContainer_title: "מידע על האבן שנבחרה",

    CartScreen_Title: "העגלה שלך",
    FloatingCartHeaderComponent_Title: "העגלה שלך",
    DiscountComponent_Title: "קוד הנחה",
    DiscountComponent_SubmitButton: `הפעל קוד`,
    DiscountComponent_Lable: "קוד הנחה",
    TotalItemsComponent_Subtotal: "סכום ביניים",
    TotalItemsComponent_Discount: "הנחה",
    TotalItemsComponent_DiscountCode: "קוד הנחה",
    TotalItemsComponent_Shipping: "משלוח",
    TotalItemsComponent_Total: "סך הכל",
    TotalItemsComponent_Items: "פריטים",
    CheckoutButtonComponent_ButtonText: "לתשלום",
    CartInformationScreen_Title: "כתובת משלוח",
    CartInformationScreen_Inputs_Country: "מדינה",
    CartInformationScreen_Inputs_Country_ErrorMessage: "הכנס מדינה",
    CartInformationScreen_Inputs_FirstName: "שם פרטי",
    CartInformationScreen_Inputs_FirstName_ErrorMessage: "הכנס שם פרטי",
    CartInformationScreen_Inputs_LastName: "שם משפחה",
    CartInformationScreen_Inputs_LastName_ErrorMessage: "הכנס שם משפחה",
    CartInformationScreen_Inputs_Email: "אימייל",
    CartInformationScreen_Inputs_Email_ErrorMessage: "הכנס אימייל",
    CartInformationScreen_Inputs_Phone: "טלפון",
    CartInformationScreen_Inputs_Address: "כתובת",
    CartInformationScreen_Inputs_Address_ErrorMessage: "הכנס כתובת",
    CartInformationScreen_Inputs_Apartment: `דירה (אופציונלי)`,
    CartInformationScreen_Inputs_PostalCode: "מיקוד",
    CartInformationScreen_Inputs_City: "עיר",
    CartInformationScreen_Inputs_City_ErrorMessage: "הכנס עיר",
    CartInformationScreen_SubmitButton: `המשך למשלוח`,
    CartInformationScreen_NextButton: "המשך",
    CartInformationScreen_CancelButton: "ביטול",
    CartInformationScreen_LoginRequired_bodymessge: `על מנת להמשיך עם ההזמנה עליכם להתחבר`,
    CartInformationScreen_ShippingCartEmpty_bodymessge: `הסל שלך ריק! הוסף פריטים כדי להמשיך לתשלום.`,
    CartInformationScreen_LoginRequired_buttonmessage1: `התחברות`,
    CartInformationScreen_LoginRequired_buttonmessage2: `ביטול`,
    CartShippingInformationScreen_Title: `שיטת משלוח`,
    CartShippingInformationScreen_SubmitButton: `המשך לתשלום`,
    shippingInternational: `תעריף אחיד בינלאומי`,
    OrderShippingContainer_ShipTo: `משלוח אל`,
    OrderShippingContainer_ChangeButton: `שנה`,
    OrderShippingContainer_ShippingMeathod_Title: `שיטת משלוח`,
    CartPaymentScreen_Title: `תשלום`,
    CartPaymentScreen_Inputs_CardNumber : `מספר כרטיס`,
    CartPaymentScreen_Inputs_CardNumber_ErrorMessage : `הכנס מספר כרטיס`,
    CartPaymentScreen_Inputs_NameOfCard : `שם על הכרטיס`,
    CartPaymentScreen_Inputs_NameOfCardr_ErrorMessage : `הכנס שם על הכרטיס`,
    CartPaymentScreen_Inputs_ExpirationDate : `תאריך תפוגה (חודש/שנה)`,
    CartPaymentScreen_Inputs_ExpirationDate_ErrorMessage  : `הכנס תאריך תפוגה`,
    CartPaymentScreen_Inputs_SecurityCode : `קוד אבטחה`,
    CartPaymentScreen_Inputs_SecurityCode_ErrorMessage : `הכנס קוד אבטחה`,
    CartPaymentScreen_SubmitButton: `בצע הזמנה`,
    ItemListEmptyListComponent_Title: `כרגע אין לנו מוצרים בקטגוריה זאת`,
    ItemListEmptyListComponent_SubTitle1: `לחצו`,
    ItemListEmptyListComponent_SubTitle2: `כאן`,
    ItemListEmptyListComponent_SubTitle3: `על מנת לראות במוצרים החדשים והמעודכנים שלנו!`,
    HomeScreen_: ``,
};