import { Platform } from "react-native";

// export const apikey =
// Platform.OS === "web" ? 
// "https://localhost:44351" // Web Development
// : "http://1a4b0e929c5e.ngrok.app"; // Device Development

 export const apikey = "https://api-app-20240814083320.kindwave-bec97f2c.westeurope.azurecontainerapps.io"; // Prod
 
 export const secretKey  = "MySuperSecretKey123";

//  export const apikey = "https://timelessmemories.ashysky-58fee057.northeurope.azurecontainerapps.io"; // Old Prod, not working anymore

// ngrok http https://localhost:44351 -host-header="localhost:44351"

// eas build --profile development --platform android

// eas build --profile production --platform android

// npx expo export:web

// if i want to switch from 'apk' to 'aab' file i need to switch (in eas.json file)
// this buildType": "apk"
// to this: "buildType": "app-bundle"