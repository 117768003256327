import { getDiscountPrice, getTotalPrice } from "./getShopInfo";

export const getOrderCheckoutJson = (userSettingsState, shopState, DataToSend) => {
  let OrderCheckoutVM = {
    Order: {
      OrderId: 0, // server need to give OrderId
      OrderDate: new Date(), // server need to update that too
      OrderStatus: 0,
      PaymentStatus: 0,
      UserId: userSettingsState?.userSettings?.UserId,
      ShippingAddressID: 0, // server need to assign that
      ShippingTypeID: shopState.shippingMethod.Id, // server need to assign that
      PaymentID: 0, // server need to assign that
      Subtotal: getTotalPrice(shopState, false, false),
      DiscountCode: shopState.discount?.discountCode,
      Discounts: getDiscountPrice(shopState),
      Taxes: 0, // right now i dont count taxes
      Total: getTotalPrice(shopState, true, true),
      SpecialInstructions: "",
      GiftWrap: false,
      GiftMessage: "",
      },
      ShippingType: {
        ShippingTypeId: shopState.shippingMethod.Id,
        Type: shopState.shippingMethod.type,
        Amount: shopState.shippingMethod.price,
      },
      Payment: {
        PaymentId: 0, // server need to give OrderId
        Amount: getTotalPrice(shopState, true, true),
        CardNumber: DataToSend ? DataToSend?.CardNumber : "",
        NameOnCard: DataToSend ? DataToSend?.NameOnCard : "",
        SecurityCode: DataToSend ? DataToSend?.SecurityCode : "",
        ExpirationDateMonth: DataToSend ? DataToSend?.ExpirationDate.slice(0, 1) : "",
        ExpirationDateYear: DataToSend ? DataToSend?.ExpirationDate.slice(2, 3) : "",
        PaymentMethod: "Card",
        PaymentMethodId: 0, // server need to give OrderId
        PaymentStatus: 0,
        PaymentDate: new Date(),
        PaymentTransactionId: "PaymentTransactionId", // server need to give OrderId
      },
      ShippingAddress: {
        AddressId: 0, // server need to give OrderId
        UserId: userSettingsState?.userSettings?.UserId,
        Country: shopState.shippingDetails.Country,
        City: shopState.shippingDetails.City,
        FirstName: shopState.shippingDetails.FirstName,
        LastName: shopState.shippingDetails.LastName,
        AddressLine: shopState.shippingDetails.Address,
        Apartment: shopState.shippingDetails.Apartment,
        PostalCode: shopState.shippingDetails.PostalCode,
        PhoneNumber: shopState.shippingDetails.Phone,

      },
      OrderItems: [],
  };
  console.log(`shopState.cart.forEach(item => {`);
  shopState.cart.forEach(item => {
    console.log(`item is `);
    console.log(item);
    const isWithDiscount = item.item.Discount > 0;
    const FinalPrice = isWithDiscount ? (item.item.Price * (100 - item.item.Discount) / 100).toFixed(2) : item.item.Price;
    const OrderItem = {
      OrderItemId: 0, // server need to give OrderId
      OrderId: 0, // server need to give OrderId
      ProductId: item.item.Id, // server need to give OrderId
      ProductName: item.item.Name,
      FrontSideText: item.item.frontSideText,
      BackSideText: item.item.backSideText,
      ChainSize: item.item.chainSize,
      BraceletSize: item.item.braceletSize,
      RingSize: item.item.ringSize,
      MaterialColor: item.item.materialColor?.replace(/_/g, ' '),
      StoneColor: item.item.stoneColor,
      ImagePath: getFileName( item.item.Image ? item.item.Image : item.item.Images?.length > 0 ? item.item.Images[0] : ""),
      Quantity: item.quantity,
      UnitPrice: FinalPrice,
      TotalPrice: FinalPrice * item.quantity,
    };
    OrderCheckoutVM.OrderItems.push(OrderItem);
  });
  return OrderCheckoutVM;
    };

    export const getFileName = (responsiveStyles, url) => {
      try{
        if(responsiveStyles.isWeb){
          // Remove the leading part of the URL
          const cleanedUrl = url.replace("/static/media/", "");
          
          // Split the string at the dot and return the first part
          const fileNameWithExtension = cleanedUrl.split('.')[0];
          
          // Add the extension back
          const fileExtension = cleanedUrl.split('.').pop();
          
          return `${fileNameWithExtension}.${fileExtension}`;
        }
        else{
          let decodedUrl = decodeURIComponent(url);
          let startIndex = decodedUrl.indexOf('images/') + 'images/'.length;
          let endIndex = decodedUrl.indexOf('?platform');

          // Extract the substring
          let imagePath = decodedUrl.substring(startIndex, endIndex);

          console.log(`decodedUrl, imagePath is ${imagePath}`);
          return imagePath;
        }
      }
      catch(err){
        console.log(`func getFileName failed, err: ${err}`);
      }
    };