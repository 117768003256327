import React from 'react';
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native';
import Svg, { Circle, Path } from 'react-native-svg';
import i18n from '../../hooks/useI18n';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import { GlobalStyles } from '../../constants/styles';

const StoneCircle = ({
    userSettingsState,
    hoveredColor,
    stoneColor,
    setStoneColor,
    setHoveredColor,
    ValueOfStoneColorType,
}) => {

    const responsiveStyles = useResponsiveStyles(userSettingsState);
    const styles = styling(responsiveStyles);

    const svgColors = 
    ValueOfStoneColorType === 'Mother_Of_Pearl' ? 
    {
        MainColor: "#f5f5f5",
        SecondaryColor: "#e0e0e0",
    }
    : ValueOfStoneColorType === 'Malachite' ? 
    {
        MainColor: "#004d40",
        SecondaryColor: "#00796b",
    }
    : ValueOfStoneColorType === 'Black_Agate' ? 
    {
        MainColor: "#000000",
        SecondaryColor: "#2c2c2c",
    }
    : ValueOfStoneColorType === 'Red_Agate' ? 
    {
        MainColor: "#b0261c",
        SecondaryColor: "#7d1c14",
    }
    : ValueOfStoneColorType === 'Lapis_Lazuli' ? 
    {
        MainColor: "#273e89",
        SecondaryColor: "#1c2954",
    }
    : ValueOfStoneColorType === `Tiger's_Eye` ? 
    {
        MainColor: "#4b2e26",
        SecondaryColor: "#d4a017",
    }: 
    {
        MainColor: "black",
        SecondaryColor: "white",
    };
  return (
    <TouchableOpacity
    onMouseEnter={() => {
        if(setHoveredColor){
            setHoveredColor(ValueOfStoneColorType)
        }
    }}
    onMouseLeave={() => {
        if(setHoveredColor){
            setHoveredColor(null)
        }
    }}
    activeOpacity={1}
    onPress={() => {
        if(setStoneColor){
            setStoneColor(ValueOfStoneColorType)
        }
    }}
    >
        <View style={styles.container}>
      <Svg height="25" width="25" viewBox="0 0 100 100" style={{borderRadius: 50}}>
        {/* עיגול חיצוני */}
        <Circle cx="50" cy="50" r="50" fill={svgColors.MainColor} />
        
        {/* חלק גלי */}
        <Path
          d="M 0 50 Q 25 30, 50 50 T 100 50 L 100 100 L 0 100 Z"
          fill={svgColors.SecondaryColor}
        />
      </Svg>
      </View>
      {hoveredColor === 'Mother_Of_Pearl' && ValueOfStoneColorType === 'Mother_Of_Pearl' ? (
        <View style={styles.tooltip}>
          <Text style={styles.tooltipText}>{i18n.t('store.PendentColorPickerContainer_MotherOfPearl')}</Text>
        </View>
     )
    : hoveredColor === 'Malachite' && ValueOfStoneColorType === 'Malachite' ? 
    <View style={styles.tooltip}>
            <Text style={styles.tooltipText}>{i18n.t('store.PendentColorPickerContainer_Malachite')}</Text>
          </View>
    : hoveredColor === 'Black_Agate' && ValueOfStoneColorType === 'Black_Agate' ? 
    <View style={styles.tooltip}>
            <Text style={styles.tooltipText}>{i18n.t('store.PendentColorPickerContainer_BlackAgate')}</Text>
          </View>
    : hoveredColor === 'Red_Agate' && ValueOfStoneColorType === 'Red_Agate' ? 
    <View style={styles.tooltip}>
            <Text style={styles.tooltipText}>{i18n.t('store.PendentColorPickerContainer_RedAgate')}</Text>
          </View>
    : hoveredColor === 'Lapis_Lazuli' && ValueOfStoneColorType === 'Lapis_Lazuli' ? 
    <View style={styles.tooltip}>
            <Text style={styles.tooltipText}>{i18n.t('store.PendentColorPickerContainer_LapisLazuli')}</Text>
          </View>
    : hoveredColor === `Tiger's_Eye` && ValueOfStoneColorType === `Tiger's_Eye` ? 
    <View style={styles.tooltip}>
            <Text style={styles.tooltipText}>{i18n.t('store.PendentColorPickerContainer_TigersEye')}</Text>
          </View>
    : null
    }
    </TouchableOpacity>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    flex: 1,
    backgroundColor: 'transparent', // Will be overridden dynamically
  },
  tooltip: {
      position: 'absolute',
      top: -40, // Position above the color container
      left: '50%',
      transform: [{ translateX: '-50%' }], // Corrected syntax
      padding: 5,
      backgroundColor: 'black',
      borderRadius: 5,
      zIndex: 1, // Ensure tooltip is above other content
    },
    tooltipText: {
      color: 'white',
      fontSize: responsiveStyles.fontSize_H5,
      fontFamily: GlobalStyles.fonts.application_font,
      minWidth: 150,
      textAlign:'center'
    },
});

export default StoneCircle;
