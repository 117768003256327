import React, { useState } from "react";
import { StyleSheet, View, Text, ScrollView, TouchableOpacity } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import { AntDesign } from '@expo/vector-icons';

const FAQSComponent = ({ userSettingsState }) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);

  const [expandedIndex, setExpandedIndex] = useState(null);

  const faqs = [
    {
      question: i18n.t("store.faq_nfc_devices"),
      answer: i18n.t("store.faq_nfc_devices_answer")
    },
    {
      question: i18n.t("store.faq_nfc_usage"),
      answer: i18n.t("store.faq_nfc_usage_answer")
    },
    {
      question: i18n.t("store.faq_nfc_scan"),
      answer: i18n.t("store.faq_nfc_scan_answer")
    },
    {
      question: i18n.t("store.faq_custom_design"),
      answer: i18n.t("store.faq_custom_design_answer")
    },
    {
      question: i18n.t("store.faq_shipping_time"),
      answer: i18n.t("store.faq_shipping_time_answer")
    },
    {
      question: i18n.t("store.faq_warranty"),
      answer: i18n.t("store.faq_warranty_answer")
    }
  ];

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <View style={styles.container}>
      <Text style={styles.headerText}>{i18n.t("store.faqs_header")}</Text>
      <ScrollView>
        {faqs.map((faq, index) => (
          <View key={index} style={styles.faqItem}>
            <TouchableOpacity
              style={styles.rowHeaderContainer}
              onPress={() => toggleExpand(index)}
            >
              <Text style={styles.question}>{faq.question}</Text>
              <AntDesign
                size={!responsiveStyles.isMobile ? 18 : 14}
                color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor}
                name={expandedIndex === index ? "caretdown" : "caretup"}
              />
            </TouchableOpacity>
            {expandedIndex === index && (
              <Text style={styles.answer}>{faq.answer}</Text>
            )}
          </View>
        ))}
      </ScrollView>
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    // padding: 20,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
    paddingRight: responsiveStyles.isTablet || responsiveStyles.isMobile ? 30 + 40 : null,
    paddingTop: 15,
  },
  headerText: {
    fontFamily: GlobalStyles.fonts.application_font,
    fontSize: responsiveStyles.fontSize_H3,
    fontWeight: 'bold',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    marginBottom: 20,
    textAlign: 'center',
  },
  faqItem: {
    marginBottom: 20,
  },
  rowHeaderContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: GlobalStyles.colors.TitleBorderBottomColor,
  },
  question: {
    fontFamily: GlobalStyles.fonts.application_font,
    fontSize: responsiveStyles.fontSize_H4,
    fontWeight: 'bold',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  },
  answer: {
    fontFamily: GlobalStyles.fonts.application_font,
    fontSize: responsiveStyles.fontSize_H5,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    marginTop: 10,
  }
});

export default FAQSComponent;
