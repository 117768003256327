import React, { useContext, useEffect, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, Image, Dimensions, ScrollView, Platform, useWindowDimensions, TouchableOpacity } from 'react-native';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { Context as ShopContext } from "../../context/ShopContext";
import { GlobalStyles } from '../../constants/styles';
import Screen from "../../components/Screen";
import i18n from '../../hooks/useI18n';
import { GenericAlert } from '../../components/alert/GenericAlert';
import { fixedScreenHeight } from '../../constants/ScreenDimensions';
import { StoreItems } from '../../constants/store/Store_Items';
import DescriptionContainer from '../../components/itemDetailsScreen/DescriptionContainer';
import RatingAndPurchaseContainer from '../../components/itemDetailsScreen/RatingAndPurchaseContainer';
import DevileryDetailsContainer from '../../components/itemDetailsScreen/DevileryDetailsContainer';
import ColorPickerContainer from '../../components/itemDetailsScreen/PendentColorPickerContainer';
import ButtonTypeButton from '../../components/inputs/ButtonTypeButton';
import CommentSectionPopup from '../../components/CommentSectionPopup';
import RatingSectionPopup from '../../components/RatingSectionPopup';
import PriceComponent from '../../components/itemDetailsScreen/PriceComponent';
import StoreBreadcrumbs from '../../components/store/StoreBreadcrumbs';
import { getNavigationParams } from '../../functions/navigationLogic';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNavigation, useRoute } from '@react-navigation/native';
import NotFoundComponent from '../../components/NotFoundComponent';
import ItemNameComponent from '../../components/itemDetailsScreen/ItemNameComponent';
import ImageSlider from '../../components/media/ImageSlider';
import BuisnessFooter from '../../components/store/BuisnessFooter';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import DetailsContainer from '../../components/itemDetailsScreen/DetailsContainer';
import EngravingTextContainer from '../../components/itemDetailsScreen/EngravingTextContainer';
import JewelryLengthContainer from '../../components/itemDetailsScreen/JewelryLengthContainer';
import FAQSComponent from '../../components/itemDetailsScreen/FAQSComponent';
import ShareIconsComponent from '../../components/itemDetailsScreen/ShareIconsComponent';
import MaterialColorPickerContainer from '../../components/itemDetailsScreen/MaterialColorPickerContainer';
import PendentColorPickerContainer from '../../components/itemDetailsScreen/PendentColorPickerContainer';
import CoverColorInfoContainer from '../../components/itemDetailsScreen/CoverColorInfoContainer';

const ItemDetailsScreen = ({  }) => {
  
  const {state: userSettingsState } = useContext(UserSettingsContext);
    const responsiveStyles = useResponsiveStyles(userSettingsState);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
  const location = responsiveStyles.isWeb ? useLocation() : null;
  const IdFromURL = location ? 
  location?.pathname?.substring(location?.pathname.lastIndexOf("/") + 1)
  : useRoute()?.params;
  const navigationParams = responsiveStyles.isWeb ? location?.state : getNavigationParams(navigation);
    
    const {state: shopState, addOrUpdateItemToCart } = useContext(ShopContext);
    
    const styles = styling(responsiveStyles);
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);
    const [frontSideText, setFrontSideText] = useState('');
    const [backSideText, setBackSideText] = useState('');
    const [chainSize, setChainSize] = useState(null);
    const [braceletSize, setBraceletSize] = useState(null);
    const [ringSize, setRingSize] = useState(null);
    const [materialColor, setMaterialColor] = useState(null);
    const [stoneColor, setStoneColor] = useState(null);

    const Id = navigationParams?.Id || Number(IdFromURL)|| 0;
    const item = StoreItems.find(storeItem => 
      storeItem.Items.find(item => item.Id === Id && item.IsActive === true)
  );
  let foundItem = null;
  let mainItemId = 0;
  if(item){
    foundItem = item.Items.find(item => item.Id === Id);
    mainItemId = item.Id;
  }
  

  const [dialogVisible, setDialogVisible] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [openCart, setOpenCart] = useState(false);

  const openDialog = (item) => {
    setDialogVisible(true);
  };

  const closeDialog = () => {
    setDialogVisible(false);
  };

  const backAction = () => {
    responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      return true;
    };
    useEffect(() =>{
      if(responsiveStyles.isWeb){
        document.title = "Item Details";
      }
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
      if(responsiveStyles.isWeb){
        window.scrollTo(0, 0);
      }
    return () => backHandler.remove();
    },[]);


    const [isStoneMenuOpen, setIsStoneMenuOpen] = useState(false);

  return <Screen style={styles.container}
  userSettingsState={userSettingsState}
  navigation={navigation}
  showCart={true}
  openCart={openCart}
  setOpenCart={setOpenCart}
  isStoneMenuOpen={isStoneMenuOpen}
  setIsStoneMenuOpen={setIsStoneMenuOpen}
  >
    {!foundItem ? 
    <View style={[styles.container]}>
      <View>
      <StoreBreadcrumbs 
      userSettingsState={userSettingsState}
      navigation={navigation}
      itemListName={"404"}
      />
      <NotFoundComponent userSettingsState={userSettingsState} navigation={navigation} />
      </View>
    </View>
    : <ScrollView style={[styles.container]}>
      <View>
        <View style={styles.header}>
        <GenericAlert
      userSettingsState={userSettingsState}
      navigation={navigation}
      bodymessge={bodymessge}
      visible={GenericAlertVisible}
      setVisible={setGenericAlertVisible}
      />
      <View>
      <StoreBreadcrumbs 
      userSettingsState={userSettingsState}
      navigation={navigation} 
      itemListName={responsiveStyles.Language === "he-IL" ? item.Title_he : item.Title_en} 
      itemDetailName={responsiveStyles.Language === "he-IL" ? foundItem.Name_he : foundItem.Name_en}
      itemListId={mainItemId}
      />
      </View>

      <View style={styles.itemContainer}>
        <View style={styles.imageContainer}>
      <ImageSlider 
      userSettingsState={userSettingsState} 
      images={foundItem.Images.map(itemImage => ({ uri: itemImage }))}
      altImageName={`${responsiveStyles.Language === "he-IL" ? foundItem.Name_he : foundItem.Name_en} ${i18n.t('store.ItemDetailsScreen_Description_text1')}`}
      isFromItemDetails={true}
       />
       {
        responsiveStyles.isDesktop || responsiveStyles.isLaptop ?
        <View style={{
        width: responsiveStyles.isDesktop || responsiveStyles.isLaptop 
        ? responsiveStyles.screenWidth * 0.4 : responsiveStyles.screenWidth,
        marginLeft: responsiveStyles.isDesktop || responsiveStyles.isLaptop 
        ? responsiveStyles.screenWidth * 0.1 : null,
      }}>
       <FAQSComponent userSettingsState={userSettingsState} />
       </View>
       : null }

      </View>
      <View style={styles.itemDetailsContainer}>

      <ItemNameComponent foundItem={foundItem} userSettingsState={userSettingsState} />
        
      <View style={styles.subContainer}>
      <View style={{paddingHorizontal: 15}}>

        
  
        <DescriptionContainer userSettingsState={userSettingsState} foundItem={foundItem} quantity={quantity} setQuantity={setQuantity} openDialog={openDialog} />
        <JewelryLengthContainer 
        userSettingsState={userSettingsState} 
        foundItem={foundItem}
        chainSize={chainSize}
        setChainSize={setChainSize}
        braceletSize={braceletSize}
        setBraceletSize={setBraceletSize}
        ringSize={ringSize}
        setRingSize={setRingSize}
         />
          <MaterialColorPickerContainer 
         foundItem={foundItem}
         userSettingsState={userSettingsState}
         materialColor={materialColor}
         setMaterialColor={setMaterialColor}
         />
         <PendentColorPickerContainer 
         foundItem={foundItem}
         userSettingsState={userSettingsState}
         stoneColor={stoneColor}
         setStoneColor={setStoneColor}
         />
         <CoverColorInfoContainer
         userSettingsState={userSettingsState}
         foundItem={foundItem}
         stoneColor={stoneColor}
         isStoneMenuOpen={isStoneMenuOpen}
         setIsStoneMenuOpen={setIsStoneMenuOpen}
         />
         <EngravingTextContainer
        foundItem={foundItem} 
        userSettingsState={userSettingsState}
        frontSideText={frontSideText}
        setFrontSideText={setFrontSideText}
        backSideText={backSideText}
        setBackSideText={setBackSideText}
         />
         <PriceComponent foundItem={foundItem} userSettingsState={userSettingsState} quantity={quantity} setQuantity={setQuantity} />
      <View>
      <View style={styles.buttonContainer}>
          <ButtonTypeButton
          userSettingsState={userSettingsState}
          // styleButton={{backgroundColor: GlobalStyles.colors.AddButton}}
          style={styles.button}
           onPress={() => {
            addOrUpdateItemToCart({
              ...foundItem, 
              frontSideText, 
              backSideText,
              chainSize,
              braceletSize,
              ringSize,
              materialColor,
              stoneColor,
            }, foundItem.Id, quantity);
            setOpenCart(true);
          }}
          >
            {i18n.t('store.AddToCart')}
      </ButtonTypeButton>
      <RatingSectionPopup
              isVisible={dialogVisible}
              onClose={closeDialog}
              userSettingsState={userSettingsState}
              data={[]}
            />
      </View>
      </View>

      <ShareIconsComponent 
      userSettingsState={userSettingsState}
      ProductName={responsiveStyles.Language === "he-IL" ? foundItem.Name_he : foundItem.Name_en}
      ProductPrice={foundItem.Discount > 0 ? (foundItem.Price * (100 - foundItem.Discount) / 100).toFixed(2) : foundItem.Price}
      URL={`https://timelessmemories-jewelry.com/ItemDetails/${foundItem.Id}`}
      />

        <DetailsContainer userSettingsState={userSettingsState} foundItem={foundItem} />
        {/* <DevileryDetailsContainer foundItem={foundItem} userSettingsState={userSettingsState} /> */}
        {
        responsiveStyles.isTablet || responsiveStyles.isMobile ?
        <View style={{
        width: responsiveStyles.isDesktop || responsiveStyles.isLaptop 
        ? responsiveStyles.screenWidth * 0.5 : responsiveStyles.screenWidth
      }}>
       <FAQSComponent userSettingsState={userSettingsState} />
       </View>
       : null }
        
 
        
      </View>
      


      </View>
      </View>
      </View>
      </View>
      <BuisnessFooter
      userSettingsState={userSettingsState}
      navigation={navigation}
      />
      </View>
    </ScrollView>
    }
    </Screen>

}

ItemDetailsScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
  header: {
    // paddingHorizontal: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.05 : null,
    // paddingHorizontal: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.15 : null,
    paddingBottom: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenHeight * 0.1 : 10,
  },
  subContainer: {
    // flex: 1,
    // width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.6 : null,
    // alignSelf: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'center' : null,
    
  },

  text: {
    color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    },


    buttonContainer: {
      alignItems:'center',
      flex:1,
      marginBottom: 20,
      marginTop: 20,
    },
    button: {
      width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.15 : responsiveStyles.screenWidth * 0.9,
      
    },
    itemContainer: {
      flex:1,
      flexDirection: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.isRTL ? 'row-reverse' : 'row' : null,
      justifyContent: responsiveStyles.isDesktop ? null : 'space-around',
      // width: responsiveStyles.screenWidth * 0.7,
      // backgroundColor: 'blue',
      // transform: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? [{ scale: 0.9 }] : [{ scale: 1 }],
    },
    imageContainer: {
      // flex: 1,
      // backgroundColor: 'blue',
      paddingHorizontal: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 15 : null,
      transform: responsiveStyles.isTablet ? [{ scale: 0.95 }] : [{ scale: 1 }],
    },
    itemDetailsContainer: {
      // flex: 1,
      marginHorizontal: responsiveStyles.isDesktop ? responsiveStyles.screenWidth * 0.1 :
       responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.02 : 20,
      // backgroundColor:'blue',
      maxWidth: responsiveStyles.isDesktop ? 525 : responsiveStyles.isLaptop ? 450 : null,

    },
});

export default ItemDetailsScreen;
