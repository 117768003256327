import React, { useContext, useEffect, useState  } from 'react';
import { View, StyleSheet, Text, BackHandler, ScrollView, Image, TouchableOpacity, Linking, Dimensions, Platform, useWindowDimensions } from 'react-native';
import { Context as UserSettingsContext } from "../../context/UserSettingsContext";
import { GlobalStyles } from '../../constants/styles';
import Screen from "../../components/Screen";
import i18n from '../../hooks/useI18n';
import { GenericAlert } from '../../components/alert/GenericAlert';
import { fixedScreenHeight } from '../../constants/ScreenDimensions';
import { defaultImage } from '../../constants/images';
import StepContainer from '../../components/store/StepContainer';
import HeaderImageContainer from '../../components/store/HeaderImageContainer';
import StepMemoriesContainer from '../../components/store/StepMemoriesContainer';
import GuaranteesGrid from '../../components/store/GuaranteesGrid';
import BuisnessFooter from '../../components/store/BuisnessFooter';
import VideoPlayer from '../../components/store/VideoPlayer';
import VideoMediaPrimary from '../../components/images/VideoMediaPrimary';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNavigation } from '@react-navigation/native';
import { getNavigationRouteName } from '../../functions/navigationLogic';
import useResponsiveStyles from '../../functions/ResponsiveStyles';
import Step4TextComponent from '../../components/tutorial/Step4TextComponent';
import Step4Container from '../../components/store/Step4Container';
import { getAsset } from '../../functions/getAsset';
import OurStoryContainer from '../../components/store/OurStoryContainer';
import ReviewsContainer from '../../components/store/ReviewsContainer';
import SecondaryContainer from '../../components/store/SecondaryContainer';
import StoreContainers from '../../components/store/StoreContainers';

let FastImage = null;
if(Platform.OS === "web"){
    FastImage = Image;
}
else{
    FastImage = require('react-native-fast-image');
}

const HomeScreen = ({  }) => {
  
  const {state: userSettingsState } = useContext(UserSettingsContext);
  
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);
  const navigation = responsiveStyles.isWeb ? useNavigate() : useNavigation();
    const [bodymessge, setbodymessge] = useState('');
    const [GenericAlertVisible, setGenericAlertVisible] = useState(false);
    
    const backAction = () => {
      responsiveStyles.isWeb ? navigation(-1) : navigation.pop();
      return true;
    };
    useEffect(() =>{
      if(responsiveStyles.isWeb){
        document.title = "Home";
      }

      if(responsiveStyles.isWeb){
        window.scrollTo(0, 0);
      }
      const backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        backAction,
      );
    return () => backHandler.remove();
    },[]);

    const [scrollY, setScrollY] = useState(0);

  return <Screen
  userSettingsState={userSettingsState}
  navigation={navigation}
  showCart={true}
  >
    <ScrollView 
    style={styles.container} 
    showsVerticalScrollIndicator={false}
    onScroll={(event) => setScrollY(event.nativeEvent.contentOffset.y)} // Track scroll position
    scrollEventThrottle={16} // Ensure smooth tracking of scroll position
    >
      <HeaderImageContainer 
      userSettingsState={userSettingsState}
      showScannable={false}
      showNfcButton={true}
      scrollY={scrollY}
      navigation={navigation}
      />
      <View style={styles.subContainer}>
      
  
      {/* steps of tutorial */}
      {/* <StepContainer 
      userSettingsState={userSettingsState} 
      navigation={navigation}
      scrollY={scrollY}
      /> */}


      
      <StoreContainers
      userSettingsState={userSettingsState} 
      navigation={navigation}
      scrollY={scrollY}
      />

{/* 
      <Step4Container 
      userSettingsState={userSettingsState} 
      navigation={navigation}
      scrollY={scrollY}
      /> */}
      

      <StepMemoriesContainer 
      userSettingsState={userSettingsState} 
      navigation={navigation}
      scrollY={scrollY}
      />

<OurStoryContainer 
      userSettingsState={userSettingsState} 
      navigation={navigation}
      scrollY={scrollY}
      />


      <SecondaryContainer
      userSettingsState={userSettingsState}
      scrollY={scrollY}
      />

      <ReviewsContainer
      userSettingsState={userSettingsState}
      scrollY={scrollY}
      />
      {/* {console.log()} */}
    </View>
      <GuaranteesGrid
      userSettingsState={userSettingsState}
      scrollY={scrollY}
       />

      <BuisnessFooter
      userSettingsState={userSettingsState}
      navigation={navigation}
      />
    </ScrollView>
    </Screen>

}

HomeScreen.navigationOptions = (navData) => {
  return {
    header: () => false,
  }
  };
  const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },
  subContainer: {
    flex: responsiveStyles.isWeb ? null : 1,
    // width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.7 : responsiveStyles.screenWidth * 0.8,
    alignSelf: responsiveStyles.isWeb ? null : 'center',
  },
  stepContainer: {
    marginHorizontal: 20,

  },
  stepTitle: {
    fontSize: responsiveStyles.fontSize_H3,
    fontWeight: '600',
    marginBottom: 5,
    fontFamily: GlobalStyles.fonts.application_font,
    textAlign: 'center',
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  },
  fullImageContainer: {
    // marginLeft: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? -(responsiveStyles.screenWidth * 0.15) : -(responsiveStyles.screenWidth * 0.1),
  },
  ImageContainer: {
    width: responsiveStyles.screenWidth,
    height: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 400 : 200,
    // aspectRatio: (16 / 9),
    marginBottom: 5,
    
  },
  image: {
    width: responsiveStyles.screenWidth,
    aspectRatio: (80 / 29),
    // height: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.4 : 300,
    borderBottomEndRadius: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 15,
    borderBottomStartRadius: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 50 : 15,
  },

});

export default HomeScreen;
