import React, { useRef, useEffect, useState, useContext } from 'react';
import { TouchableOpacity, StyleSheet, Dimensions, View, Text, Animated, FlatList, Image, ScrollView, useWindowDimensions, Platform } from 'react-native';
import { navigate } from '../../navigationRef';
import i18n from '../../hooks/useI18n';
import { GlobalStyles } from '../../constants/styles';
import { Context as ShopContext } from '../../context/ShopContext';
import { AntDesign } from '@expo/vector-icons';
import CartEmptyListComponent from '../displayComponent/CartEmptyListComponent';
import QuantityStoreItemComponent from './QuantityStoreItemComponent';
import TextInputWithErrorComponent from '../forms/TextInputWithErrorComponent';
import DiscountComponent from './_DiscountComponent';
import TotalItemsComponent from './TotalItemsComponent';
import ButtonTypeButton from '../inputs/ButtonTypeButton';
import ShoppingCartComponent from './ShoppingCartComponent';
import CheckoutButtonComponent from './CheckoutButtonComponent';
import FloatingCartHeaderComponent from './FloatingCartHeaderComponent';
import ShoppingCartFullInfoComponent from './ShoppingCartFullInfoComponent';
import useResponsiveStyles from '../../functions/ResponsiveStyles';


const StoreMenuOpen = ({ 
  onPress, 
  navigation,
  userSettingsState,
  setIsStoreMenuOpen,
  isStoreMenuOpen,
}) => {
  
  const responsiveStyles = useResponsiveStyles(userSettingsState);
const styles = styling(responsiveStyles);

  const slideAnimation = useRef(new Animated.Value(0)).current;
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (isStoreMenuOpen) {
      // Slide in animation
      setShouldRender(true);
      Animated.timing(slideAnimation, {
        toValue: 1,
        duration: 500, // Adjust animation duration as needed
        useNativeDriver: true,
      }).start(() => {
        // Animation completed, now render the component
      });
    } else {
      // Slide out animation
      Animated.timing(slideAnimation, {
        toValue: 0,
        duration: 500, // Adjust animation duration as needed
        useNativeDriver: true,
      }).start(() => {
        // Animation completed, now hide the component
        setShouldRender(false);
      });
    }
  }, [isStoreMenuOpen]);

  const menuTranslateX = slideAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [responsiveStyles.isRTL ? -responsiveStyles.screenWidth * 0.8 : responsiveStyles.screenWidth * 0.8, 0],
  });

  if (!shouldRender) {
    return null;
  }
  
  return (
    <TouchableOpacity
      style={styles.backdrop}
      activeOpacity={1}
      onPress={(event) => {
        const { locationX, locationY } = event.nativeEvent;
        const { pageX, pageY } = event.nativeEvent;
        console.log( event.nativeEvent);
        
        const TouchX = responsiveStyles.isWeb ? pageX : locationX;
        const TouchY = responsiveStyles.isWeb ? pageY : locationY;
        if (
          locationX < responsiveStyles.screenWidth * 0.1 || // Touch outside left edge
          // locationX > responsiveStyles.screenWidth || // Touch outside right edge
          locationY < 60 || // Touch above the menu
          locationY > responsiveStyles.screenHeight // Touch below the menu
        )
        console.log(`TouchX ${TouchX}, TouchY: ${TouchY}, responsiveStyles.screenWidth: ${responsiveStyles.screenWidth * 0.1} responsiveStyles.isRTL: ${responsiveStyles.isRTL}`);
        if(responsiveStyles.isRTL){
          if (
            TouchX < 0 || // Touch outside left edge
            TouchX > (responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.3 : responsiveStyles.screenWidth * 0.8) || // Touch outside right edge
            TouchY < 0 || // Touch above the menu
            TouchY > responsiveStyles.screenHeight // Touch below the menu
          ) {
            setIsStoreMenuOpen(false);
          }
        }
        else if (
          TouchX < (responsiveStyles.isDesktop || responsiveStyles.isLaptop ? (responsiveStyles.screenWidth - responsiveStyles.screenWidth * 0.3) : (responsiveStyles.screenWidth - responsiveStyles.screenWidth * 0.8)) || // Touch outside right edge
          TouchY < 0 || // Touch above the menu
          TouchY > responsiveStyles.screenHeight // Touch below the menu
        ) {
          setIsStoreMenuOpen(false);
        }
      }}
    >
      <Animated.View
        style={[
          styles.menuContainer,
          {
            transform: [{ translateX: menuTranslateX }],
          },
        ]}
      >
          <ScrollView>

          <ShoppingCartFullInfoComponent
          userSettingsState={userSettingsState} 
          showFloatingCartHeader={true}
          setIsStoreMenuOpen={setIsStoreMenuOpen}
          navigation={navigation}
          isFromStoreMenu={true}
          />

      </ScrollView>
      </Animated.View>
    </TouchableOpacity>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  backdrop: {
    // position:'fixed',
    position: responsiveStyles.isWeb ? 'fixed' : 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    zIndex: 999, // Ensure it's below the menu
    height: responsiveStyles.screenHeight,
  },
  menuContainer: {
    position: 'absolute',
    top: 0,
    right: !responsiveStyles.isRTL ? 0 : null,
    left: responsiveStyles.isRTL ? 0 : null,
    // width: responsiveStyles.screenWidth * 0.95,
    width: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.screenWidth * 0.3 : responsiveStyles.screenWidth * 0.8, // Adjust width as needed
    backgroundColor: 'white',
    elevation: 5, // For Android shadow
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    zIndex: 1000, // Ensure it's above other components
    height: '100%', // Fill the screen height
    padding: 20,
    backgroundColor: GlobalStyles[responsiveStyles.ThemeValue]?.primaryBackgroundColor,
  },


  title: {
    fontWeight: 'bold',
    fontSize: responsiveStyles.fontSize_H4,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  text: {
    color:GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H6,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontFamily: GlobalStyles.fonts.application_font,
},






});

export default StoreMenuOpen;
