import React, { useEffect, useState } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import StoneCircle from "./StoneCircle";

const PendentColorPickerContainer = ({ 
  userSettingsState, 
  foundItem,
  stoneColor,
  setStoneColor,
 }) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);

  const [hoveredColor, setHoveredColor] = useState(null);

  
  const isHasMaterial = foundItem.IsMotherOfPearl || foundItem.IsMalachite || foundItem.IsBlackAgate
  || foundItem.IsRedAgate || foundItem.IsLapisLazuli || foundItem.IsTigersEye;

  useEffect(() =>{
    if(stoneColor === null){
      if(foundItem.IsMotherOfPearl){
        setStoneColor('Mother_Of_Pearl');
      }
      else if(foundItem.IsMalachite){
        setStoneColor('Malachite');
      }
      else if(foundItem.IsBlackAgate){
        setStoneColor('Black_Agate');
      }
      else if(foundItem.IsRedAgate){
        setStoneColor('Red_Agate');
      }
      else if(foundItem.IsLapisLazuli){
        setStoneColor('Lapis_Lazuli');
      }
      else if(foundItem.IsTigersEye){
        setStoneColor(`Tiger's_Eye`);
      }
    }
    },[]);

  if(!isHasMaterial)
  {
    return null;
  }


  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.text}>{i18n.t('store.PendentColorPickerContainer_title')}:</Text>
      </View>

      {
        foundItem.IsMotherOfPearl ?
        <View style={[styles.colorMainContainer, stoneColor === 'Mother_Of_Pearl' ? styles.colorWrapper : null]}>
          <StoneCircle 
          userSettingsState={userSettingsState}
          hoveredColor={hoveredColor}
          stoneColor={stoneColor}
          setStoneColor={setStoneColor}
          setHoveredColor={setHoveredColor}
          ValueOfStoneColorType='Mother_Of_Pearl'
          />
      </View>
      : null
      }


{
        foundItem.IsMalachite ?
        <View style={[styles.colorMainContainer, stoneColor === 'Malachite' ? styles.colorWrapper : null]}>
          <StoneCircle 
          userSettingsState={userSettingsState}
          hoveredColor={hoveredColor}
          stoneColor={stoneColor}
          setStoneColor={setStoneColor}
          setHoveredColor={setHoveredColor}
          ValueOfStoneColorType='Malachite'
          />
      </View>
      : null
      }


{
        foundItem.IsBlackAgate ?
        <View style={[styles.colorMainContainer, stoneColor === 'Black_Agate' ? styles.colorWrapper : null]}>
          <StoneCircle 
          userSettingsState={userSettingsState}
          hoveredColor={hoveredColor}
          stoneColor={stoneColor}
          setStoneColor={setStoneColor}
          setHoveredColor={setHoveredColor}
          ValueOfStoneColorType='Black_Agate'
          />
      </View>
      : null
      }


{
        foundItem.IsRedAgate ?
        <View style={[styles.colorMainContainer, stoneColor === 'Red_Agate' ? styles.colorWrapper : null]}>
          <StoneCircle 
          userSettingsState={userSettingsState}
          hoveredColor={hoveredColor}
          stoneColor={stoneColor}
          setStoneColor={setStoneColor}
          setHoveredColor={setHoveredColor}
          ValueOfStoneColorType='Red_Agate'
          />
      </View>
      : null
      }


{
        foundItem.IsLapisLazuli ?
        <View style={[styles.colorMainContainer, stoneColor === 'Lapis_Lazuli' ? styles.colorWrapper : null]}>
          <StoneCircle 
          userSettingsState={userSettingsState}
          hoveredColor={hoveredColor}
          stoneColor={stoneColor}
          setStoneColor={setStoneColor}
          setHoveredColor={setHoveredColor}
          ValueOfStoneColorType='Lapis_Lazuli'
          />
      </View>
      : null
      }


{
        foundItem.IsTigersEye ?
        <View style={[styles.colorMainContainer, stoneColor === `Tiger's_Eye` ? styles.colorWrapper : null]}>
          <StoneCircle 
          userSettingsState={userSettingsState}
          hoveredColor={hoveredColor}
          stoneColor={stoneColor}
          setStoneColor={setStoneColor}
          setHoveredColor={setHoveredColor}
          ValueOfStoneColorType={`Tiger's_Eye`}
          />
      </View>
      : null
      }

      
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    // flex: 1,
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    marginBottom: 20,
  },
  colorWrapper: {
    padding: 2, // Add small padding between border and color
    borderRadius: 50, // Ensure borderRadius matches the border
    borderColor: GlobalStyles[responsiveStyles.ThemeValue]?.iconColor,
    borderWidth: 1,
    marginTop: -2,
  },
  colorMainContainer: {
    marginLeft: !responsiveStyles.isRTL ? 20 : null,
    marginRight: responsiveStyles.isRTL ? 20 : null,
  },
  colorContainer: {
    width: 25,
    height: 25,
    borderRadius: 50, // Matches the wrapper's borderRadius
    backgroundColor: 'transparent', // Will be overridden dynamically
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
  },
});

export default PendentColorPickerContainer;
