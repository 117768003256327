import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, View, Text, Platform, Dimensions } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import Animated, { useSharedValue, useAnimatedStyle, withTiming } from "react-native-reanimated"; // For mobile animation
import DownArrow from "./DownArrow";
import ButtonTypeButton from "../inputs/ButtonTypeButton";
import { navigateToScreen } from "../../navigationResponsive";
import Step1TextComponent from "../tutorial/Step1TextComponent";
import Step2TextComponent from "../tutorial/Step2TextComponent";
import Step3TextComponent from "../tutorial/Step3TextComponent";
import { transitionTime } from "../../functions/ScreenDimensionsLogic";

const StepContainer = ({ userSettingsState, navigation, scrollY = 0 }) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);
  const windowHeight = Dimensions.get("window").height; // Get window height for viewport calculation

  const sectionRef = useRef(null);
  const [layoutY, setLayoutY] = useState(0); // Save the Y position of the component
  const [videoLayoutY, setVideoLayoutY] = useState(0); // Save the Y position of the component
  const [isVisible, setIsVisible] = useState(false); // For web visibility

  // For mobile (Reanimated)
  const contentOpacity = useSharedValue(0);
  const contentTranslateY = useSharedValue(50);

  // Animated styles (Reanimated for mobile)
  const animatedContentStyle = useAnimatedStyle(() => ({
    opacity: withTiming(contentOpacity.value, { duration: transitionTime }),
    transform: [{ translateY: withTiming(contentTranslateY.value, { duration: transitionTime }) }],
  }));

  // Trigger animation for mobile
  const triggerAnimation = () => {
    contentOpacity.value = 1;
    contentTranslateY.value = 0;
  };

  // Handle visibility detection for mobile
  useEffect(() => {
    if (Platform.OS !== "web") {
      if (layoutY !== 0 && (scrollY >= layoutY - windowHeight && scrollY <= layoutY + windowHeight)) {
        triggerAnimation(); // Trigger when it's within the viewport
      }
    }
  }, [scrollY, layoutY]);

  const handleLayout = (event) => {
    const { y } = event.nativeEvent.layout;
    setLayoutY(y); // Save the Y position
    if (scrollY === 0 && y < windowHeight) {
      triggerAnimation(); // Trigger animation if the view is initially visible
    }
  };
  const handleVideoLayout = (event) => {
    let {height} = event.nativeEvent.layout;
    setVideoLayoutY(height); // Save the Y position
  };

  // Intersection Observer for Web
  useEffect(() => {
    if (Platform.OS === "web") {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
              observer.disconnect(); // Disconnect after the animation triggers
            }
          });
        },
        { threshold: 0.1 } // Trigger when 10% of the section is visible
      );
      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }

      return () => {
        if (sectionRef.current) {
          observer.unobserve(sectionRef.current);
        }
      };
    }
  }, []);

  return (
    <Animated.View // Use Animated.View instead of View for animations
      ref={sectionRef}
      style={[
        styles.container,
        Platform.OS === "web" && isVisible
          ? { opacity: 1, transform: "translateY(0px)", transition: "opacity 1s ease-out, transform 1s ease-out" }
          : { opacity: 0, transform: "translateY(50px)" }, // Hidden until visible
        Platform.OS !== "web" ? animatedContentStyle : null,
      ]}
      onLayout={handleLayout} // Capture layout for mobile
    >
      <Text style={styles.sectionTitle}>
        {i18n.t("store.HomeScreen_StepContainer_SectionTitle")}
      </Text>
      <View style={styles.stepsContainer}>
        {/* Step 1 */}
        <Step1TextComponent
          userSettingsState={userSettingsState}
          navigation={navigation}
          isFromHome={true}
          handleVideoLayout={handleVideoLayout}
        />

        <DownArrow userSettingsState={userSettingsState} minHeight={videoLayoutY} />

        {/* Step 2 */}
        <Step2TextComponent
          userSettingsState={userSettingsState}
          navigation={navigation}
          isFromHome={true}
        />

        <DownArrow userSettingsState={userSettingsState} minHeight={videoLayoutY} />

        {/* Step 3 */}
        <Step3TextComponent
          userSettingsState={userSettingsState}
          navigation={navigation}
          isFromHome={true}
        />
      </View>

      <View style={styles.buttonContainer}>
        <ButtonTypeButton
          userSettingsState={userSettingsState}
          style={styles.button}
          onPress={() => {
            navigateToScreen(navigation, "StoreFlow", `Tutorial`, null, null);
          }}
        >
          {i18n.t("tutorial.ViewAllTutorials")}
        </ButtonTypeButton>
      </View>
    </Animated.View>
  );
};

const styling = (responsiveStyles) =>
  StyleSheet.create({
    container: {
      paddingVertical: 20,
    },
    sectionTitle: {
      fontSize: responsiveStyles.fontSize_H1,
      fontFamily: GlobalStyles.fonts.application_font,
      fontWeight: "bold",
      marginBottom: 10,
      textAlign: "center",
      color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
      paddingBottom: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 40 : null,
    },
    stepsContainer: {
      flexDirection:
        responsiveStyles.isDesktop || responsiveStyles.isLaptop
          ? responsiveStyles.isRTL
            ? "row-reverse"
            : "row"
          : "column", // Responsive for mobile and desktop
      justifyContent: "space-around",
      alignItems: "center",
      // transform: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? [{ scale: 0.9 }] : [{ scale: 1 }],
      alignSelf:'center'
    },
    buttonContainer: {
      alignItems: "center",
    },
    button: {
      width:
        responsiveStyles.isDesktop || responsiveStyles.isLaptop
          ? responsiveStyles.screenWidth * 0.1 - 30
          : responsiveStyles.isTablet
          ? responsiveStyles.screenWidth * 0.2
          : responsiveStyles.screenWidth * 0.4,
      minWidth: 220,
      paddingTop: 10,
    },
  });

export default StepContainer;
