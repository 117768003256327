import React, { useState } from "react";
import { StyleSheet, View, Text, useWindowDimensions, TouchableOpacity } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import BulletText from "../inputs/BulletText";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import { AntDesign } from '@expo/vector-icons';
import BraceletSizeGuide from "./BraceletSizeGuide";


const DetailsContainer = ({ 
  userSettingsState, 
  foundItem,
 }) => {

   const [sizeGuideVisible, setSizeGuideVisible] = useState(false);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [descriptionVisible, setDescriptionVisible] = useState(false);
  const responsiveStyles = useResponsiveStyles(userSettingsState);
	const styles = styling(responsiveStyles);
  return (
    <View style={styles.container}>
      <TouchableOpacity
      style={[styles.rowHeaderContainer, styles.borderBottomStyle]}
      onPress={() => setSizeGuideVisible(!sizeGuideVisible)}
      >
          <Text style={styles.headerText}>{i18n.t('store.ItemDetailsScreen_DetailsContainer_sizeGuide_header')}</Text>
          <AntDesign size={!responsiveStyles.isMobile ? 18 : 14} 
    color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} 
      name={sizeGuideVisible ? "caretdown" : "caretup" }
      />
      </TouchableOpacity>

      { sizeGuideVisible ? 
    <View>
      <BraceletSizeGuide userSettingsState={userSettingsState} />
    </View>
      : null }

<TouchableOpacity
      style={[styles.rowHeaderContainer, styles.borderBottomStyle]}
      onPress={() => setDetailsVisible(!detailsVisible)}
      >
          <Text style={styles.headerText}>{i18n.t('store.ItemDetailsScreen_DetailsContainer_details_header')}</Text>
          <AntDesign size={!responsiveStyles.isMobile ? 18 : 14} 
    color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} 
      name={detailsVisible ? "caretdown" : "caretup" }
      />
      </TouchableOpacity>

      { detailsVisible ? 
    <View style={{ paddingBottom: 10, }}>
      <BulletText userSettingsState={userSettingsState} 
      text={`${i18n.t('store.ItemDetailsScreen_DetailsContainer_materials')}: ${responsiveStyles.Language === "he-IL" ? foundItem?.Material_he : foundItem?.Material_en}`}
      />
      <BulletText userSettingsState={userSettingsState} 
      text={`${i18n.t('store.ItemDetailsScreen_DetailsContainer_dimensions')}: 
      ${i18n.t('store.ItemDetailsScreen_DetailsContainer_dimensions_chain')}: ${foundItem?.Dimensions_ChainLength} ${i18n.t('store.ItemDetailsScreen_DetailsContainer_dimensions_chain_text1')}
      ${i18n.t('store.ItemDetailsScreen_DetailsContainer_dimensions_pendant')}: ${foundItem?.Dimensions_PendantSize} ${i18n.t('store.ItemDetailsScreen_DetailsContainer_dimensions_pendant_text1')}`}
      />
      <BulletText userSettingsState={userSettingsState} 
      text={`${i18n.t('store.ItemDetailsScreen_DetailsContainer_weight')}: ${foundItem?.Weight} ${i18n.t('store.ItemDetailsScreen_DetailsContainer_weight_text1')}`}
      />
      </View>
      : null }

      <TouchableOpacity
      style={[styles.rowHeaderContainer, styles.borderBottomStyle]}
      onPress={() => setDescriptionVisible(!descriptionVisible)}
      >
          <Text style={styles.headerText}>{i18n.t('store.ItemDetailsScreen_DetailsContainer_description_header')}</Text>
          <AntDesign size={!responsiveStyles.isMobile ? 18 : 14} 
    color={GlobalStyles[responsiveStyles.ThemeValue]?.iconColor} 
      name={descriptionVisible ? "caretdown" : "caretup" }
      />
      </TouchableOpacity>

      { descriptionVisible ? 
    <View>
      <BulletText userSettingsState={userSettingsState} 
      text={`${responsiveStyles.Language === "he-IL" ? foundItem?.Description_he : foundItem?.Description_en}`}
      withBullet={false}
      />
      </View>
      : null }
    </View>
  );
};

const styling = (responsiveStyles) => StyleSheet.create({
  container: {
    // marginBottom: 20,
    marginTop: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 53 : 30,
  },
  bulletContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  bullet: {
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  },
  bulletText: {
    flex: 1,
    lineHeight: responsiveStyles.fontSize_H5,
    paddingLeft: 5, // adjust padding as needed to align with the bullet point
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
  },
  itemNameContainer: {
  },
  borderBottomStyle: {
    marginBottom: 20,
    paddingBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: GlobalStyles.colors.TitleBorderBottomColor, 
  },
  descriptionText: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    paddingBottom: 10,
    fontWeight: '600',
  },
  text: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H5,
    fontFamily: GlobalStyles.fonts.application_font,
  },
  rowHeaderContainer: {
    flexDirection: responsiveStyles.isRTL ? 'row-reverse' : 'row',
    justifyContent: 'space-between', 
    alignItems: 'center',
    paddingBottom: 20,
},
  headerText: {
    color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
    fontSize: responsiveStyles.fontSize_H4,
    fontFamily: GlobalStyles.fonts.application_font,
    fontWeight: 'bold',
},
});

export default DetailsContainer;
