import React, { useEffect, useState, useRef } from "react";
import { StyleSheet, View, Text, Platform, Dimensions, Image } from "react-native";
import { GlobalStyles } from "../../constants/styles";
import i18n from "../../hooks/useI18n";
import useResponsiveStyles from "../../functions/ResponsiveStyles";
import Animated, { useSharedValue, useAnimatedStyle, withTiming } from 'react-native-reanimated'; // For mobile animation
import { transitionTime } from "../../functions/ScreenDimensionsLogic";
import { getAsset } from "../../functions/getAsset";

let FastImage = null;
if(Platform.OS === "web"){
    FastImage = Image;
}
else{
    FastImage = require('react-native-fast-image');
}

const OurStoryContainer = ({ 
  userSettingsState,
  navigation,
  scrollY // Accept scrollY from parent ScrollView for mobile
}) => {
  const responsiveStyles = useResponsiveStyles(userSettingsState);
  const styles = styling(responsiveStyles);

  const sectionRef = useRef(null);
  const [layoutY, setLayoutY] = useState(0); // Save the Y position of the component
  const windowHeight = Dimensions.get("window").height;

  // For mobile (Reanimated)
  const contentOpacity = useSharedValue(0);
  const contentTranslateY = useSharedValue(50);

  // For web (useState-based animation control)
  const [isVisible, setIsVisible] = useState(false);

  // Animated styles (Reanimated for mobile)
  const animatedContentStyle = useAnimatedStyle(() => ({
    opacity: withTiming(contentOpacity.value, { duration: transitionTime }),
    transform: [{ translateY: withTiming(contentTranslateY.value, { duration: transitionTime }) }],
  }));

  // Trigger animation for mobile
  const triggerAnimation = () => {
    contentOpacity.value = 1;
    contentTranslateY.value = 0;
  };

  // Handle visibility detection for mobile
  useEffect(() => {
    if (Platform.OS !== "web") {
      // Ensure layoutY has been set and scrollY is valid
      if (layoutY !== 0 && (scrollY >= layoutY - windowHeight && scrollY <= layoutY + windowHeight)) {
        triggerAnimation(); // Trigger when it's within the viewport
      }
    }
  }, [scrollY, layoutY]);

  const handleLayout = (event) => {
    const { y } = event.nativeEvent.layout;
    setLayoutY(y); // Save the Y position
    // If the view is visible right after layout calculation, trigger the animation
    if (scrollY === 0 && y < windowHeight) {
      triggerAnimation(); // First view is already in the viewport on initial load
    }
  };

  // Intersection Observer for Web
  useEffect(() => {
    if (Platform.OS === "web") {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
              observer.disconnect(); // Disconnect observer after triggering
            }
          });
        },
        { threshold: 0.1 } // Trigger when 10% of the section is visible
      );
      if (sectionRef.current) {
        observer.observe(sectionRef.current);
      }

      return () => {
        if (sectionRef.current) {
          observer.unobserve(sectionRef.current);
        }
      };
    }
  }, []);

  return (
    <Animated.View // Use Animated.View for mobile animation
      ref={sectionRef}
      style={[
        styles.container,
        Platform.OS === "web" && isVisible
          ? { opacity: 1, transform: "translateY(0px)", transition: "opacity 1s ease-out, transform 1s ease-out" }
          : Platform.OS === "web"
          ? { opacity: 0, transform: "translateY(50px)" } // Web: Hidden until visible
          : animatedContentStyle, // Mobile: Animated style
      ]}
      onLayout={handleLayout} // Capture layout information on mobile
    >
      

        <View style={styles.mainContainer}>
        <View style={[styles.secondaryContainer, 
          { alignItems:'center', 
          justifyContent: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 'center' : null,
          paddingBottom: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? null : 100,
          }]}>
      <Text style={[styles.sectionTitle]}>
        {i18n.t("store.HomeScreen_OurStoryContainer_SectionTitle")}
      </Text>
      <Text style={styles.text}>
        {i18n.t("store.HomeScreen_OurStoryContainer_story_section1")}
      </Text>
      <Text style={styles.text}>
        {i18n.t("store.HomeScreen_OurStoryContainer_story_section2")}
      </Text>
      <Text style={styles.text}>
        {i18n.t("store.HomeScreen_OurStoryContainer_story_section3")}
      </Text>
      {/* <Text style={styles.text}>
        {i18n.t("store.HomeScreen_OurStoryContainer_Section1_1")}
        <Text style={[styles.text, styles.textBold]}>
          {i18n.t("store.HomeScreen_OurStoryContainer_Section1_2")}
        </Text>
        <Text style={styles.text}>
          {i18n.t("store.HomeScreen_OurStoryContainer_Section1_3")}
        </Text>
      </Text>
      <Text style={styles.text}>
        {i18n.t("store.HomeScreen_OurStoryContainer_Section2")}
      </Text>
      <Text style={styles.text}>
        {i18n.t("store.HomeScreen_OurStoryContainer_Section3")}
      </Text>
      <Text style={styles.text}>
        {i18n.t("store.HomeScreen_OurStoryContainer_Section4")}
      </Text> */}
      </View>
      <View style={[styles.secondaryContainer]}>
        <FastImage
                source={{ uri: getAsset(require("../../../assets/images/store/images/our_story.jpg")) }}
                resizeMode={responsiveStyles.isMobile ? null : "contain"}
                style={styles.image}
              />
      </View>
      </View>

      <Text style={styles.sloganText}>
        {i18n.t("store.HomeScreen_OurStoryContainer_Slogan")}
        </Text>


    </Animated.View>
  );
};

const styling = (responsiveStyles) =>
  StyleSheet.create({
    container: {
      
      // paddingHorizontal:
      //   responsiveStyles.isDesktop || responsiveStyles.isLaptop
      //     ? responsiveStyles.screenWidth * 0.15
      //     : responsiveStyles.screenWidth * 0.1,
    },
    mainContainer: {
      flex: 1,
      flexDirection: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? responsiveStyles.isRTL ? 'row-reverse' : 'row' : 'column',
      marginVertical: 40,
      // borderTopWidth: 1,
      // borderTopColor: GlobalStyles.colors.Gold,
      // borderBottomWidth: 1,
      // borderBottomColor: GlobalStyles.colors.Gold,
    },
    secondaryContainer: {
      flex: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 1 : null,
      // alignItems: responsiveStyles.isRTL ? 'flex-start' : 'flex-end',
    },
    sectionTitle: {
      fontSize: responsiveStyles.fontSize_H3,
      fontFamily: GlobalStyles.fonts.application_font,
      fontWeight: "bold",
      marginBottom: 10,
      textAlign: "center",
      color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
      paddingBottom: responsiveStyles.isDesktop || responsiveStyles.isLaptop ? 40 : null,
    },
    text: {
      fontSize: responsiveStyles.fontSize_H5,
      fontFamily: GlobalStyles.fonts.application_font,
      marginBottom: 15,
      color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
      maxWidth: 430,
    },
    textBold: {
      fontWeight: "bold",
    },
    sloganText: {
      fontSize: responsiveStyles.fontSize_H3,
      fontFamily: GlobalStyles.fonts.handWrite_font,
      fontWeight: "bold",
      // marginVertical: 15,
      marginTop: 40,
      color: GlobalStyles[responsiveStyles.ThemeValue]?.textColor,
      // color: GlobalStyles.colors.Gold,
      textAlign: 'center',
    },
    image: {
      // width: responsiveStyles.isDesktop || responsiveStyles.isLaptop 
      // ? responsiveStyles.screenWidth * 0.4
      // : responsiveStyles.screenWidth,
      // aspectRatio: responsiveStyles.isMobile ? (5 / 6) : (16 / 9),
      width: '100%',
      aspectRatio: 1,
    },
  });

export default OurStoryContainer;
